import images from "./images"

const feedbackData = [
    {
        id: 0,
        image: images.quote,
        text: 'Коли постало питання про стелі, відразу виникло багато суперечок що краще - гіпсокартон, штукатурка ... Переглянули купу журналів, інтернет, визначилися - натяжні стелі! Дякуємо Star Sky. Дуже красиво, робота ідеальна.',
        name: 'Марія',
        date: '18.07.2022'
    },
    {   
        id: 1,
        image: images.quote,
        text: 'Ми собі робили матові натяжні стелі. Дуже красиво вийшло. Замовляли в компанії "Star Sky" Якість хороша і ціни в цій компанії прийнятні. Рекомендую! Роблять швидко і якісно.',
        name: 'Ігор',
        date: '09.06.2022'
    },
    {
        id: 2,
        image: images.quote,
        text: 'Велике спасибі компанії за виконану роботу. Дуже професійний та дружній підхід. Все робилося вчасно і дуже якісно. Якість дуже хороша. Раджу!!!',
        name: 'Сергій',
        date: '22.01.2022'
    },
    {
        id: 3,
        image: images.quote,
        text: 'З цією компанією розпочав співпрацю на початку 2021 року і поступово з їх допомогою робив натяжні стелі по всій квартирі.',
        name: 'Роман',
        date: '04.10.2021'
    },
]

export default feedbackData